import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import LayoutExtra from "../components/layoutExtra"
import { Table } from 'react-bootstrap'

const ElectricPaymentService = () => {

	const data = useStaticQuery(graphql`
    query {
			paymentIcon: file(relativePath: { eq: "icons/payment.svg" }) {
        publicURL
      },
    }
	`)

	return (
		<LayoutExtra>
			<SEO title="電子決済等代行業について" />
			<div className="page-head">
				<div className="h2-subtitle">
					<img src={data.paymentIcon.publicURL} alt="電子決済等代行業について" />
					<h2>電子決済等代行業について</h2>
					<p>Privacy Policy</p>
				</div>
				<p>当社の本サービスにおける銀行法及び銀行法施行規則に基づく電子決済等代行業に係る表示は以下のとおりです。</p>
			</div>

			<h3>1. 電子決済等代行業者の商号、名称及び住所</h3>
			<Table responsive className="table-bordered">
				<tbody>
					<tr>
						<th>商号</th>
						<td>株式会社　グローバルワイズ</td>
					</tr>
					<tr>
						<th>住所</th>
						<td>〒450-0003　愛知県名古屋市中村区名駅南二丁目14番19号</td>
					</tr>
				</tbody>
			</Table>
			<hr className="dots-hr" />

			<h3>2. 電子決済等代行業者の権限に関する事項</h3>
			<p>
				利用者からの同意を得た上で、連携先金融機関口座の情報参照及び振込依頼・振込指図の伝達を行います。<br />
				当社は銀行法第２条第17項第１号及び２号に掲げる電子決済等代行業者としての業務を行うものであり、連携先金融機関を代理する権限を有しません。
				当社が行う電子決済等代行業者としての業務は、連携先金融機関が行うものではありません。
			</p>
			<hr className="dots-hr" />

			<h3>3. 電子決済等代行業者の損害賠償に関する事項</h3>
			<p>
				当社は、本サービスに関連して利用者が被った損害について、当社に故意又は重過失があったときを除き、一切賠償の責任を負いません。
				なお、当社に故意又は重過失があった場合、及び消費者契約法の適用その他の理由により、
				本項その他当社の損害賠償責任を免責する規定にかかわらず当社が利用者に対して損害賠償責任を負う場合においても、
				当社の賠償責任の範囲は、当社の責に帰すべき事由により現実に発生した直接かつ通常の損害に限られるものとし、かつ、
				その賠償額は損害の事由が生じた時点から遡って過去1年間に当該利用者から現実に受領した本サービスの利用料金の総額を上限とします。
			</p>
			<hr className="dots-hr" />

			<h3>4. 電子決済等代行業に関する利用者からの苦情又は相談に応ずる営業所又は事務所の連絡先</h3>
			<p>
				株式会社　グローバルワイズ<br />
				〒450-0003　愛知県名古屋市中村区名駅南二丁目14番19号<br />
				Eメール：support@ecochange.jp<br />
				受付時間：年末年始・土日祝日を除く　平日１０時から１８時<br />
			</p>
			<hr className="dots-hr" />

			<h3>5. 登録番号</h3>
			<Table responsive className="table-bordered">
				<tbody>
					<tr>
						<th>登録番号</th>
						<td>東海財務局長（電代）第２号</td>
					</tr>
					<tr>
						<th>登録年月日</th>
						<td>2019年4月3日</td>
					</tr>
				</tbody>
			</Table>
			<hr className="dots-hr" />

			<h3>6. 利用者が支払うべき手数料、報酬若しくは費用の金額若しくはその上限額又はこれらの計算方法</h3>
			<Table responsive className="table-bordered">
				<thead>
					<tr>
						<th colSpan={2}>サービス利用料金</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th>サービス名称</th>
						<td>EcoChange for ZEDI</td>
					</tr>
					<tr>
						<th rowSpan={2}>初期費用（税別）</th>
						<td>発注企業　150,000円</td>
					</tr>
					<tr>
						<td>受注企業　15,000円</td>
					</tr>
					<tr>
						<th>月額費用（税別）</th>
						<td>5,000円</td>
					</tr>
				</tbody>
			</Table>
			<p className="mt-4"><strong>サービス内容</strong></p>
			<ul>
				<li>注文・注文回答機能</li>
				<li>引合・見積機能</li>
				<li>出荷・検収機能</li>
				<li>請求機能（入金消込含む）</li>
				<li>ZEDI連携による振込指図機能</li>
				<li>ZEDI連携による入出金明細および振込入金通知照会機能</li>
				<li>メールおよび電話によるお客様サポート（年末年始・土日祝日を除く　平日１０時から１８時）</li>
			</ul>
			<hr className="dots-hr" />

			<h3>7. 銀行法第２条第17項第１号に掲げる行為を行う場合において、決済指図に係る為替取引の上限額を設定している場合には、その額</h3>
			<p>
				設定しておりません。
			</p>
			<hr className="dots-hr" />

			<h3>8. 利用者との間で継続的に電子決済等代行業に係る行為を行う場合には、契約期間及び契約期間の中途で解約時の取扱い（手数料、報酬又は費用の計算方法を含む。）</h3>
			<ul>
				<li>最小契約期間は契約成立月の1日を基準とし1年とします。</li>
				<li>利用期間の更新は、契約者の解約の申し出が無い場合、自動更新とします。</li>
				<li>解約の際は解約希望月の3か月前までに書面にてお申し出ください。</li>
				<li>契約期間満了日前の中途解約はできないこと、および利用者が支払った料金の返金請求には応じることができません。</li>
				<li>金融機関にかかる契約費用、手数料については金融機関とお客様との契約に準じます。</li>
			</ul>
			<hr className="dots-hr" />

			<h3>9. 利用者から当該利用者に係る識別符号等を取得して電子決済等代行業に係る行為を行う場合には、その旨</h3>
			<p>
				当社は利用者から識別符号（全銀協標準通信プロトコルにおける電文制御データ項目）を取得して電子決済等代行業を実施しております。
			</p>
			<hr className="dots-hr" />

			<p className="text-right mt-5">最終更新：2019年4月8日<br />株式会社グローバルワイズ<br />代表取締役  伊原栄一</p>
		</LayoutExtra>
	)
}

export default ElectricPaymentService